<template>
  <div class=" bg-white rounded-lg">
    <div class="w-full courier-detail-left-menu">
      <div class="gap-6 cursor-pointer" :class="positionButton === data.key ? 'list-menu select' : 'list-menu'" v-for="(data, index) in dataFilter" :key="index" @click="changePosition(data)">
        <!-- <img :src="data.imgSelect" /> -->
        <div v-html="positionButton === data.key ? data.imgSelect : data.imgNone"></div>
        <p class="font-semibold">
          {{ data.label }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CourierDetailLeftMenu',
  props: ['dataFilter', 'positionButton'],
  data() {
    return {}
  },
  methods: {
    // handleScroll(event) {
    //     Any code to be executed when the window is scrolled
    //     console.log('event scroll', event);
    // },
    scroll_left() {
      const content = document.querySelector('.container-tabs-filter')
      content.scrollLeft -= 50
    },
    scroll_right() {
      const content = document.querySelector('.container-tabs-filter')
      content.scrollLeft += 50
    },
    changePosition(val) {
      // console.log('change button ', val)
      // this.positionButton = val.key
      this.$emit('filterChange', val)
    }
  }
}
</script>

<style lang="scss">
.courier-detail-left-menu {
  .list-menu {
    align-items: center;
    display: flex;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-bottom: 0.5px solid #CFD8DC;

    p {
      color: #546E7A;
    }
  }

  .list-menu.select {
    background: #FFEBEE;
    border-bottom: 0.5px solid #F44336 !important;
    border-top: 0.5px solid #F44336 !important;

    p {
      color: #F44336;
    }
  }

  .list-menu.select:first-child {
    border-top: none !important;
    border-top-left-radius: 0.5rem
      /* 8px */
    ;
    border-top-right-radius: 0.5rem
      /* 8px */
    ;
  }

  .list-menu:last-child {
    border-bottom: none;
  }
  .list-menu.select:last-child {
    border-top: 0.5px solid #F44336 !important;
    border-bottom: none !important;
        border-bottom-left-radius: 0.5rem
      /* 8px */
    ;
    border-bottom-right-radius: 0.5rem
  }
}
</style>
