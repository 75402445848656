import {
  encryptDecriptMethods
} from '@/plugins/encryptDecriptMethods'
import {
  useRoute,
  useRouter
} from 'vue-router'
import {
  getCurrentInstance,
  onMounted,
  ref
} from 'vue'
import {
  courierComissionUseCase
} from '@/domain/usecase'
import TableCourierComissionIndex from '@/views/master/courier/courier-comission/detail/TableCourierComissionIndex.vue'
import CourierComissionForm from '@/views/master/courier/courier-comission/CourierComissionForm.vue'
import { useStore } from 'vuex'

export default {
  name: 'CourierComissionDetail',
  components: {
    TableCourierComissionIndex,
    CourierComissionForm
  },
  setup() {
    const app = getCurrentInstance()
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const Id = encryptDecriptMethods.decrypt(route.params.id)
    const data = ref({}) as any
    const sliderActive = ref(false)
    const showTables = ref(false)
    const op = ref()
    const {
      $toast,
      $strInd,
      $confirm
    } = app!.appContext.config.globalProperties

    const dataObjReturn = (val: any) => {
      val.MsKomisiKurirJenisKendaraans = val.KurirMsJenisKendaraans.map((result: any) => ({
        Id: result.Id,
        Kode: result.Kode,
        Kapasitas: result.Kapasitas,
        Nama: result.Nama,
        MsJenisKendaraanId: result.Id
      }))
      return val
    }

    const getDataDetail = () => {
      store.dispatch('showLoading')
      showTables.value = false
      courierComissionUseCase.getDataForm(Id).then(async (response: any) => {
        if (!response.error) {
          data.value = await dataObjReturn(response.result)
          showTables.value = true
        }
        store.dispatch('hideLoading')
      })
    }

    const showDropDown = (evt: any) => {
      op.value.toggle(evt)
    }

    const closeSidebar = () => {
      sliderActive.value = false
    }

    const editData = (val: any) => {
      sliderActive.value = true
    }

    const deleteData = (val: any) => {
      $confirm.require({
        header: 'Komisi Kurir',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await courierComissionUseCase.deleteData(val)

          if (response.error) {
            $toast.add({
              severity: 'error', detail: response.message, group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: response.result.detail ?? response.result.Detail, group: 'bc', life: 3000
            })
            router.push({
              name: 'courier-comission'
            })
          }
        }
      })
    }

    onMounted(() => {
      getDataDetail()
    })
    return {
      data,
      Id,
      sliderActive,
      closeSidebar,
      showDropDown,
      editData,
      deleteData,
      op,
      route,
      getDataDetail,
      showTables
    }
  }
}
