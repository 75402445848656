import {
  getCurrentInstance, onMounted, reactive, ref, toRef, toRefs
} from 'vue'
import { useRoute } from 'vue-router'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import {
  getSelectKota,
  courierComissionUseCase,
  jenisKendaraanUseCase
} from '@/domain/usecase'
import { useStore } from 'vuex'

export default {
  name: 'CourierComissionForm',
  props: {
    dataEdit: {
      default: () => null
    }
  },
  emits: ['reloadData', 'closeSidebar'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const {
      dataEdit
    } = toRefs(props)
    const route = useRoute()
    const store = useStore()
    const title = ref('Tambah Komisi Kurir')
    const kotaOption = ref([])
    const transportationTypeOption = ref([])
    const Id = ref(null)
    const dataForm = reactive({
      kotaId: null,
      bonus: 0,
      target: 0,
      transportationType: null as any
    })
    const submitted = ref(false)
    const rules = {
      kotaId: {
        required: helpers.withMessage('Kota harus diisi', required)
      },
      transportationType: {
        required: helpers.withMessage('Jenis Kendaraan harus diisi', required)
      }
    }

    const v$ = useVuelidate(rules, dataForm)

    const closeSidebar = () => {
      emit('closeSidebar')
    }

    const checkResponse = async (response: any) => {
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
      } else {
        if (Id.value) {
          $toast.add({
            severity: 'success',
            // summary: response.result.title,
            detail: response.result.detail ?? response.result.Detail,
            group: 'bc',
            life: 1500
          })
        } else {
          $toast.add({
            severity: 'success',
            detail: $strInd.toast.successSubmit,
            group: 'bc',
            life: 1500
          })
        }
        emit('reloadData')
        closeSidebar()
      }
    }

    const getTransportationType = () => {
      jenisKendaraanUseCase.getAll({
        select: '&$select=Id,Kapasitas,Kode,Nama',
        custom: 'IsDeleted eq false'
      }).then((response: any) => {
        transportationTypeOption.value = response.result.map((x: any) => ({
          label: x.Nama,
          value: x.Id
        }))
      })
    }

    const submitProccess = () => {
      store.dispatch('showLoading')
      const dataSend = {
        MsKotaId: dataForm.kotaId,
        // Bonus: dataForm.bonus,
        Target: dataForm.target,
        KurirMsJenisKendaraanIds: dataForm.transportationType
      }
      courierComissionUseCase.submitData(Id.value, dataSend).then((response: any) => {
        checkResponse(response)
        store.dispatch('hideLoading')
      })
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      submitProccess()
    }

    const deleteData = () => {
      $confirm.require({
        header: 'Komisi Kurir',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await courierComissionUseCase.deleteData(Id.value)
          if (response.error) {
            $toast.add({
              severity: 'error', detail: response.message, group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: response.result.detail ?? response.result.Detail, group: 'bc', life: 3000
            })
            emit('reloadData')
            closeSidebar()
          }
        }
      })
    }

    const getOptionCity = async () => {
      const filterController = '$select=Id,IsActive,Provinsi,Nama,PulauId,Kode&$filter=IsActive eq true'
      const response = await getSelectKota(filterController)
      kotaOption.value = response.result.map((x: any) => ({
        value: x.Id,
        label: x.Nama
      }))
    }

    const getDataForm = async () => {
      title.value = 'Tambah Komisi Kurir'
      if (dataEdit.value) {
        Id.value = dataEdit.value.Id
        dataForm.bonus = dataEdit.value.Bonus
        dataForm.target = dataEdit.value.Target
        dataForm.kotaId = dataEdit.value.MsKotaId
        dataForm.transportationType = dataEdit.value?.MsKomisiKurirJenisKendaraans.length > 0 ? dataEdit.value?.MsKomisiKurirJenisKendaraans?.map((x: any) => x.MsJenisKendaraanId) : null
        title.value = 'Edit Komisi Kurir'
      }
    }

    onMounted(() => {
      getDataForm()
      getOptionCity()
      getTransportationType()
    })
    return {
      route,
      title,
      deleteData,
      dataForm,
      closeSidebar,
      submitData,
      v$,
      kotaOption,
      submitted,
      transportationTypeOption
    }
  }
}
