<template>
  <section>
    <div class="grid grid-cols-3 gap-4">
      <div>
        <CourierDetailLeftMenu :dataFilter="listMenu" :positionButton="posisitionMenu" @filterChange="changePositionMenu" />
      </div>
      <div class=" col-span-2">
        <TableCourierComission :Id="Id" :DataHd="DataHd" :Posisition="posisitionMenu" v-if="Id" ref="refTableCourierComission" />
        <!-- <TableCourierComission :Id="Id" :DataHd="DataHd" :Posisition="posisitionMenu" v-if="Id && posisitionMenu === 2" /> -->
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable indent */
import CourierDetailLeftMenu from '@/views/master/courier/detail/CourierDetailLeftMenu.vue'
import iconMenu from '@/views/master/courier/detail/assetsMenuDependency'
import TableCourierComission from '@/views/master/courier/courier-comission/components/TableCourierComission.vue'

const icon = iconMenu()
export default {
  name: 'TableCourierComissionIndex',
  components: {
    CourierDetailLeftMenu,
    TableCourierComission
  },
  props: {
    Id: {
      default: () => null
    },
    DataHd: {
      default: () => []
    }
  },
  data() {
    return {
      listMenu: [{
          // imgSelect: icon.red.pencil,
          // imgNone: icon.dark.pencil,
          imgSelect: `<img src='${require('@/assets/img/icon/pencil-outline-red.svg')}' style='width:20px;height:20px;'/>`,
          imgNone: `<img src='${require('@/assets/img/icon/pencil-outline-grey.svg')}' style='width:20px;height:20px;'/>`,
          label: 'Komisi Aktual',
          key: 1
        },
        {
          imgSelect: `<img src='${require('@/assets/img/icon/moneyComissionRed.svg')}'/>`,
          imgNone: `<img src='${require('@/assets/img/icon/moneyComissionBlueGrey.svg')}'/>`,
          label: 'Prediksi Komisi',
          key: 2
        },
      ],
      posisitionMenu: 1
    }
  },
  methods: {
    async changePositionMenu(val) {
      console.log('any ref', this.$refs.refTableCourierComission)
      this.posisitionMenu = await val.key
      // eslint-disable-next-line no-unused-expressions
      this.$refs.refTableCourierComission.intialData(true, this.posisitionMenu)
    }
  }
}
</script>

<style>

</style>
